import { uid } from 'uid';

export interface Data {
  id: string;
  category: string;
  state: string;
  address: any;
}

export const dataContent: Data[] = [
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Abia',
    address: `<b className="text-primary">Aba 1</b> <br />No 5 Asa Road Former/Old Nitel Building Aba<br /><br />
    <b className="text-primary">Aba 2</b> <br />G.R.A 30 Brass Street after Jevinic Restaurant Aba<br /><br />
    <b className="text-primary">Umuahia-1</b> <br />8 Mission Hill, Opposite Villaroy Hotel, Umuahia Main Town<br /><br />
    <b className="text-primary">Umuahia-2</b> <br />No 60 Aba Road, Close to MTN Office at Aba Road, Umuahia<br /><br />
    `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Abuja',
    address: `<div className="block lg:flex lg:justify-between lg:gap-8">
    <div>
    <b className="text-primary">Area 1</b> <br />SH 034, Area 1 shopping Plaza, Area 1 Abuja<br /><br />
    <b className="text-primary">Garki</b> <br />SICCONS PLAZA, Opposite Unity House, Beside Lifemate Furniture, Garki Area 11 <br /><br />
    <b className="text-primary">Gwarimpa</b> <br />House 38, 3rd Avenue Gwarimpa, Opposite Union Bank Abuja.<br /><br />
     <b className="text-primary">Gwarimpa 2</b> <br />Suite A1 Bricks and More Plaza, 6th Avenue by 7th Avenue Junction Gwarinpa<br /><br />
     <b className="text-primary">Gwagwalada</b> <br />Secretariat road beside WAEC, opposite Aso-Oke Hotel, Gwagwalada.<br /><br />
     <b className="text-primary">Kubwa 1</b> <br />Opposite 2/2 court junction, block 43, Gado Nasko way, along El-rufai Bustop, Kubwa.<br /><br />
     <b className="text-primary">Kubwa 2</b> <br />Opposite Ignobis hotel plot 17 Gidin dutse layout, kubwa.<br /><br />
     <b className="text-primary">Kubwa 3</b> <br />Kukwaba General Park, Kubwa.<br /><br />
     <b className="text-primary">Lugbe</b> <br />Shepherd-King plaza beside Remaco fuel station by Police signboard, Lugbe.<br /><br />
     </div>
     <div>
     <b className="text-primary">Maraba Experience Center</b> <br />No 132 Giza Plaza Opp Chrisgold Plaza Beside MTN Office Mararaba.<br /><br />
     <b className="text-primary">Madalla</b> <br />Mobil Fueling Station along Zuba Express Way, Madalla.<br /><br />
     <b className="text-primary">Utako 1</b> <br />Plot 113 I.V.W. Osisiogu Street, beside Utako Police Station Utako Abuja.<br /><br />
     <b className="text-primary">Utako 2</b> <br />Abraham Plaza, Suite A13 Plot, 6 A.E. Ekukinam St, Utako Abuja.<br /><br />
     <b className="text-primary">Wuse 2</b> <br />80 Aminu Kano crescents opposite Sherif plaza beside Wema Bank Banex wuse 2<br /><br />
     <b className="text-primary">Ademola Adetokunbo</b> <br />12 Nurnberger Platz, by Lobito junction, Ademola Adetokunbo Crescent, before Transcorp Hilton, Wuse 2<br /><br />
     <b className="text-primary">Zuba</b> <br />206, Zuba Market, Opp. Lagos Line, Zuba<br /><br /> 
     </div>
     </div>
    `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Adamawa',
    address: `<b className="text-primary">Yola</b> <br />Plot 2 Bekaji Plaza, Bekaji Karewa Road, By Fire Service Roundabout, Jimeta Yola.<br /><br />
   `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Anambra',
    address: `<b className="text-primary">Awka</b> <br />Elite Shopping Complex Opp Crunchies fries, Enugu/Onitsha Expressway, Awka<br /><br />
    <b className="text-primary">Onitsha</b> <br />2 Awka Road, By DMGS Junction, Beside All Saints Anglican Cathedral, Onitsha <br /><br />
    <b className="text-primary">Nnewi</b> <br />73 – Owerri Road, Martina Chukwuma Plaza (Innoson Plaza), Opposite The Salvation Army Church, Umudim Nnewi<br /><br />
   `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Akwa Ibom',
    address: ` <b className="text-primary">Eket</b> <br /> 92, Marina Road, Opposite Royalty Hotel, Eket.<br /><br />
    <b className="text-primary">Uyo 1</b> <br />3, Monsignor Akpan Avenue, Itam industrial layout, opp Timber Market, Itam<br /><br />
    <b className="text-primary">Uyo 2</b> <br />No 108 Oron Road, beside First bank,Uyo<br /><br />
    `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Bauchi',
    address: `<b className="text-primary">Bauchi</b> <br />Shop 7, Mai Jama'a Plaza, Opposite GWARAM and Sons Plaza, Yandoka Road, Bauchi.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Bayelsa',
    address: `<b className="text-primary">Yenagoa 1</b> <br />Kpansia Epia, Opposite Wema Bank by INEC Junction, Yenogoa<br /><br />
    <b className="text-primary">Yenagoa 2</b> <br />Beside Phone Headquarters, Tamic Road Junction, Okutukutu, by Express, Yenegoa.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Benue',
    address: `<b className="text-primary">Makurdi</b> <br />No 4 Old Otukpo Rd, Opposite Dester’s by Savannah Roundabout.<br /><br />
   `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Borno',
    address: `<b className="text-primary">Maiduguri</b> <br />10A, Golden plaza, opposite Elkanemi College of Islamic Theology, Jos Road, Maiduguri<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Cross River',
    address: `<b className="text-primary">Calabar 1</b> <br />29, Ndidem Usang Iso Road ( Aka Marian Road) Calabar.<br /><br />
    <b className="text-primary">Calabar 2</b> <br />74, Eta Agbor road, Beside UNICAL,  opposite MTN office, Calabar.<br /><br />
   `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Delta',
    address: `<div className="block md:flex md:justify-between">
    <div>
    <b className="text-primary">Asaba 1</b> <br />Asaba Onitsha Express way, By Head Bridge.<br /><br />
    <b className="text-primary">Asaba 2</b> <br />445, Nnebisi Road, opposite Zenith Bank, Asaba.<br /><br />
    <b className="text-primary">Asaba 3</b> <br />Suit 53/54 Independence Mall Okpanam Rd, Asaba.<br /><br />
    <b className="text-primary">Ughelli Center</b> <br />6B, Uduere/Agbara Road, Off Ughelli-Warri Express Way, Ughelli.<br /><br />
    </div>
    <div>
    <b className="text-primary">Warri 2</b> <br />116, Effurun-Sapele Warri Road, Effurun Opp. Our Ladies High School.<br /><br />
    <b className="text-primary">Warri-Effurun Center</b> <br />Shop 5, Eku Plaza, 128 Effurun-Sapele road, Opp Solidas. Adjacent PZ Cussons by 7up Bus stop.<br /><br />
    </div>
    </div>
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Ebonyi',
    address: `<b className="text-primary">Abakaliki</b> <br />Central Park, opposite International Market, Abakaliki<br /><br />
    `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Edo',
    address: `<div className="block md:flex md:justify-between">
    <div>
    <b className="text-primary">Akpakpava</b> <br />112, Akpakpava Road, Benin City.<br /><br />
    <b className="text-primary">Airport Road</b> <br />Shop 1, Omegatron Plaza, 47 Airport Road, Benin City.<br /><br />
    <b className="text-primary">Auchi</b> <br />Okene Express Way, Opp Auchi Polytechnic, Auchi.<br /><br />
    <b className="text-primary">Sapele Road</b> <br /> 131 Benin Sapele Road, Beside Genesis Restaurant, opposite Uwa Junction, Benin City.<br /><br />
    </div>
    <div>
    <b className="text-primary">Ekpoma</b> <br />Along Benin -Auchi expressway, Beside Big Joe park, Ekpoma.<br /><br />
    <b className="text-primary">Uselu</b> <br />202, Uselu Lagos Road, Ugbowo Benin City.<br /><br />
    <b className="text-primary">Ramat Park</b> <br />42, Benin-Agbor road, EcoBus Park, Ramat Benin City.<br /><br />
    </div>
    </div>
   `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Ekiti',
    address: `<b className="text-primary">Ado Ekiti Center</b> <br />Soladola petrol station, beside Apc secretariat, opposite moferere junction, along ikere road, Ajilosun<br /><br />
    `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Enugu',
    address: `<b className="text-primary">Enugu 1</b> <br />No 1, P & T Quarters, Market Road, Opp Osisatech Polytechnic, Enugu.<br /><br />
    <b className="text-primary">Enugu 2</b> <br />67, Zik Avenue Uwani Enugu.<br /><br />
    <b className="text-primary">Nsukka</b> <br />No 64 Owerrani, Enugu Road, Nsukka.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Gombe',
    address: `<b className="text-primary">Gombe</b> <br />Shop 4, el-zaks plaza opposite Conoil filling station along FTH/police Headquarters ashaka road, Gombe.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Gusau',
    address: `<b className="text-primary">Gusau</b> <br />C 1, A A Master Plaza Canteen Road Gusau.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Imo',
    address: `<b className="text-primary">Owerri 1</b> <br />Plot C31, Relief Road, by Relief Junction, Off Egbu Road, Owerri.<br /><br />
    <b className="text-primary">Owerri 2</b> <br /> Odonko Plaza, No 7 Nwaturuocha street, Ikenegbu Owerri.<br /><br />
    <b className="text-primary">Owerri 3</b> <br /> Shop 9 Lion Yard Plaza, plot 26A/26B Federal Housing Estate along Umuguma Road (World Bank), New Owerri.<br /><br />
   `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Jigawa',
    address: `<b className="text-primary">Dutse</b> <br />Government House Round-About, Asamau House Block B, Number 8, by Airtel Office, Dutse, Jigawa State.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Kaduna',
    address: `<b className="text-primary">Kaduna 1 </b> <br />Jos Road Plaza. 19/20, Jos Road, by Ahmadu Bello Way, Kaduna.<br /><br />
    <b className="text-primary">Kaduna 2</b> <br />Shop A04, No 6 Gidanbu Plaza, Kaduna to Lagos Road, Opposite Kaduna Old Airport Road, Kaduna.
<br /><br />
    <b className="text-primary">Kaduna 3</b> <br />Nnamdi Azikiwe Expressway by Command Junction, close to Samrada Fuel Station (beside 911 bakery).<br /><br />
    <b className="text-primary">Zaria</b> <br />Shop 2, D2 Plaza No. 18 Sokoto road beside Shagalinku London Hotel after MTD Junction, Sabon Gari Zaria.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Kano',
    address: `<b className="text-primary">Kano 1 </b> <br />1, Bompai Road by Tafawa Balewa Way, Opp Domino's Pizza, Kano.<br /><br />
    <b className="text-primary">Kano 2</b> <br />Shop 2&3 Centro Plaza, Opp BUK Old Site, Kabuga, Kano.<br /><br />
    <b className="text-primary">Kano 3</b> <br />Khadijah house, Zaria Road opposite Kano State House of Assembly<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Katsina',
    address: `<b className="text-primary">Katsina</b> <br />Abudullahi Sarki Muktar Road, Along Kiddies Round-About, Near Tukur Jikamshi Residence Katsina.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Kebbi',
    address: `<b className="text-primary">Birnin Kebbi</b> <br />Ahmadu Bello Way opp alhaji boye coca cola Depot birnin kebbi, kebbi state.<br /><br />
   `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Kogi',
    address: `<b className="text-primary">Lokoja</b> <br />No 1 IBB Way, Adankolo, Lokoja, close to Federal Medical Center.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Kwara',
    address: `<b className="text-primary">Ilorin 1</b> <br />151, Ibrahim Taiwo Road (Upper Taiwo), Adjacent Chicken Republic, Ilorin<br /><br />
    <b className="text-primary">Ilorin 2</b> <br />34B, University of Ilorin Road, Beside Reo Cakes Plaza, Tanke, Ilorin.<br /><br />
   `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Lagos',
    address: `<div className="block md:flex md:justify-between">
        <div>
        <b className="text-primary">Abule-Egba</b> <br /> 568, Abeokuta Expressway, Ajala Bus/Stop Abule-Egba. <br /><br />
        <b className="text-primary">Agbara</b> <br /> Agbeke Filling Station, Morogbo, Along Badagry Expressway Agbara, Lagos. <br /><br />
        <b className="text-primary">Alaba International</b> <br /><span className="mb-0"> Cs1 Ground Floor Corner Stone Plaza By Dobbil Avenue <br /> Along Phone Village Road, Electronics Section Alaba International Market.</span><br /><br />
        <b className="text-primary">Ajah 1</b> <br /> KM 25, Lekki-Epe Express way, Ajiwe-Ajah.<br /><br />
        <b className="text-primary">Ajah 2</b> <br /> KM 22, Lekki-Epe Express way, Opp. Jeffrey’s Plaza, by Abraham Adesanya Roundabout, Ajah.<br /><br />
        <b className="text-primary">Addo Badore</b> <br /> Tripple Ace Dew Building, opposite Enyo filling Station Addo road.<br /><br />
        <b className="text-primary">Akowonjo</b> <br /> 41 Shasha Road, Akowonjo Junction, Dopemu, Lagos.<br /><br />
        <b className="text-primary">Awoyaya</b> <br />Titi's Place, beside Royal Park Hotel, by Ogunfayo Bus stop. Km 36, Lekki Epe expressway, Eputu, Awoyaya, Lagos.<br /><br />
          <b className="text-primary">Amuwo-Odofin</b> <br />Shop A105 Cosjane Mall Opposite Diamond Estate, By Festac Link Bridge, Amuwo Odofin, Lagos.<br /><br />
        <b className="text-primary">Broad Street</b> <br /> 158 Broad street, Lagos Island. (Behind UBA head office Marina), Lagos.<br /><br />
        <b className="text-primary">Cele Okota</b> <br /> 103, Okota Road, Cele.<br /><br />
        <b className="text-primary">Epe</b> <br /> Animashaun Plaza, Beside Petrocam fuel station, Near Epe T-junction, Epe.<br /><br />
        <b className="text-primary">Festac</b> <br /> 1st Avenue Road,Festac first gate, beside Inec office, Festac town, Lagos.<br /><br />
        <b className="text-primary">Gbagada</b> <br /> 7, Hospital Rd, Ifako, Gbagada, Lagos.<br /><br />
        <b className="text-primary">Gbagada Express Center</b> <br /> GIG Logistics Digital Hub. No 1 Sunday Ogunyade Street, Gbagada Expressway,Beside Eterna Fuel Station, Gbagada Lagos.<br /><br />
        <b className="text-primary">Ikeja 1</b> <br /> 9, Medical Road, former Simbiat Abiola Way, Opp, Zenith Bank.<br /><br />
        <b className="text-primary">Ikeja 2</b> <br /> 80, Awolowo Way, Ikeja, Lagos.<br /><br />
        <b className="text-primary">Isolo</b> <br /> 43, Osolo Way, Ajao Estate, Ekwu Awolo House.<br /><br />
        <b className="text-primary">Ikoyi</b> <br /> 103 Awolowo road, Ikoyi Lagos.<br /><br />
        <b className="text-primary">Ikosi</b> <br /> 16 Ikosi Road, Ketu Lagos.<br /><br />
        <b className="text-primary">Ikorodu</b> <br /> Sabo Road Garage, Ikorodu.<br /><br />
        <b className="text-primary">Fadeyi</b> <br /> 69, Ikorodu Road, Fadeyi, Lagos<br /><br />
        <b className="text-primary">Ikotun</b> <br /> 29,Idimu Road, Opp. Local Govt, Council, Ikotun, Lagos.<br /><br />
        <b className="text-primary">Ilupeju</b> <br /> Flat 1, Block 1, LSDPC Estate Beside UBA, 12, Industrial Avenue, Cappa Bus-stop, Ilupeju, Lagos.<br /><br />
        <b className="text-primary">International trade fair</b> <br /> Shop D77 & D78, Abia Plaza, BBA, Lagos Int’ Trade Fair Complex, Lagos.<br /><br />
        </div>
        <div>
        <b className="text-primary ">Igbo Efon</b> <br /> Km 17 Scapular plaza Igbo efon.<br /><br />
        <b className="text-primary">Ipaja</b> <br />164, Lagos Abeokuta Express Way, beside Access Bank, Iyana Ipaja, Lagos.<br /><br />
        <b className="text-primary">Jibowu</b> <br />GIGM Terminal: 20 Ikorodu Express Road, Jibowu, Lagos.<br /><br />
        <b className="text-primary">Lekki Admiralty</b> <br /> No 1A, Wole Ariyo Street, Beside First Bank, Lekki Phase 1.<br /><br />
        <b className="text-primary">Lekki Admiralty 2</b> <br /> Jubilee Mall Admiralty Way, Lekki Phase One, Lekki.<br /><br />
        <b className="text-primary">Lekki Admiralty 3</b> <br /> Lekki Center, No 2, Admiralty Road, Lekki Phase 1. <br /><br />
        <b className="text-primary">Lekki (Fola Osibo)</b> <br /> Ground floor Legends Place Mall Plot 29 Fola Osibo Lekki Phase 1, Lagos.<br /><br />
        <b className="text-primary">Oniru</b> <br />Banex Mall Suite V.GL 01, Akiogun Road, Oniru, Lagos. <br /><br />
        <b className="text-primary">Old Ojo Road</b> <br />Old Ojo Road, After Agboju Bus stop, opposite Access Bank, by the police Station.<br /><br />
        <b className="text-primary">Ogba</b> <br /> 3 Ijaiye Road, Beside FCMB Ogba.<br /><br />
        <b className="text-primary">Ogudu</b> <br /> 141, Ogudu road Beside UBA, Studio24 building, Ogudu.<br /><br />
        <b className="text-primary">Ojodu Berger</b> <br />47A Ogunnusi Road,  opp  Divas cake, beside Access Bank, Ojodu Berger b/stop, Lagos.<br /><br />
        <b className="text-primary">Opebi</b> <br />62B, Opebi Road by Salvation junction Opp So-fresh, Opebi, Ikeja, Lagos<br /><br />
        <b className="text-primary">Orchid Road</b> <br />Orchid road (E-MALL Plaza) by VAN DANIEL's Estate Orchid Lagos.<br /><br />
        <b className="text-primary">Osapa (Canal Mall)</b> <br /> 2 Ganiu Eletu Way, Osapa London, Lekki-Epe Expressway, Lagos.<br /><br />
        <b className="text-primary">Oyingbo</b> <br /> No 25 Otto Causeway Opp Iddo Bus stop, Iddo Ebute Metta Lagos.<br /><br />
        <b className="text-primary">Sango</b> <br /> 3, Abeokuta – Lagos Expressway, Sango Ota, Opp. Sango Bridge.<br /><br />
        <b className="text-primary">Surulere</b> <br /> 26, Adeniran Ogunsanya, Surulere, Lagos.<br /><br />
        <b className="text-primary">Volks</b> <br /> 169, Badagry Expressway, Volkswagen Bus Stop.<br /><br />
        <b className="text-primary">Victoria Island</b> <br /> 1436 Sanusi Fafunwa Street, Victoria Island, Lagos.<br /><br />
        <b className="text-primary">Victoria Island 2</b> <br /> 272b Akin Adeshola Street, Beside Honda Place, Victoria Island, Lagos.<br /><br />
        <b className="text-primary">Yaba</b> <br />Shop G-021, Ground Floor, Tejuosho Ultra Modern Shopping complex, Ojuelegba road, Yaba.<br /><br /></div></div>
        `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Nasarawa',
    address: `<b className="text-primary">Lafia</b> <br />Shops 1 & 2 Police Officers Mess, Opposite Polaris Bank, Jos Road, Lafia.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Niger',
    address: `<b className="text-primary">Minna</b> <br />Landmark: After Mr Biggs beside Nepa Office, Farm Center Area, Tunga, Minna – Niger State.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Ogun',
    address: `<b className="text-primary">Abeokuta</b> <br />62, Tinubu Street, Ita Eko, Abeokuta, Ogun State<br /><br />
        <b className="text-primary">Abeokuta FUNAAB</b> <br />Block A, Shop 9, SSANU complex, besides Paradise, FUNAAB, Abeokuta <br /><br />
        <b className="text-primary">Sango Otta</b> <br />3, Abeokuta-Lagos Expressway, beside 9mobile office Opp. Sango Bridge, Sango Ota.<br /><br />
        <b className="text-primary">Ijebu-Ode</b> <br />102, Ibadan road opposite NEPA office Ibadan garage ijebu ode.<br /><br />
        `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Ondo',
    address: `<b className="text-primary">Akure Center</b> <br />22, Oyemekun Road, Opposite SLOT, Akure, Ondo State.<br /><br />
        <b className="text-primary">Ondo Town</b> <br />30, Yaba Street, Opposite Crunchies, Ondo Town, Ondo State.<br /><br />
        `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Osun',
    address: `<b className="text-primary">Osogbo</b> <br />KM3, Gbongan/Ibadan Road, NIPCO Petrol Station, Ogo Oluwa, Osogbo.<br /><br />
    <b className="text-primary">Ile-Ife</b> <br />EXODUS Filling Station, opposite Airtel Office, Mayfair, Ile-lfe, Osun State
<br /><br />
       `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Oyo',
    address: `<b className="text-primary">Ibadan 1</b> <br />Town Planning Complex, by Sumal Foods, Ring Road, Ibadan<br /><br />
        <b className="text-primary">Ibadan 2</b> <br />Suite 5, Kamal memorial plaze, former iyalode complex, opposite funcktionals clothing, bodija - UI road, UI Ibadan.<br /><br />
        <b className="text-primary">Ibadan 3</b> <br />Bovas Filling Station, 106/107 Agbaakin Layout adjacent olowo tin fowo shanu shopping complex, Iwo Road, Ibadan.<br /><br />
        <b className="text-primary">Ogbomoso</b> <br />Eterna Fuel Station (Akala Complex), Opp Zenith Bank Starlight Ogbomoso

<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Plateau',
    address: `<b className="text-primary">Jos 1 </b> <br />Plaza 1080, Yakubu Gowon way, Dadin kowa second gate.<br /><br />
        <b className="text-primary">Jos 2 </b> <br />Opposite Jankwano Bingham University Teaching Hospital, Jos.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Rivers',
    address: `<div className="block md:flex md:justify-between">
        <div>
        <b className="text-primary">PHC Alakahia</b> <br />Linus Book Shop Building <br /> beside Today FM Road, East-West Road PHC.<br /><br />
        <b className="text-primary">PHC Elelenwo</b> <br />No 299 Old Refinery Road, by De-Young Junction, Elelenwo, Port Harcourt.<br /><br />
        <b className="text-primary">PHC Eliozu</b> <br />emmanuel plaza, G.u Ake Road, beside planet filling station, <br /> eliogbolo, Eliozu, Port Harcourt.<br /><br />
        <b className="text-primary">PHC Woji</b> <br />Agora Plaza. 118 Woji Road, By Bodo Junction, <br>
         GRA Phase 2, Port Harcourt.<br> (Same Building with Miskay Boutique).<br /><br />
        <b className="text-primary">PHC Stadium</b> <br />9 Stadium Road, Beside Benjack, Port Harcourt<br /><br />
        <b className="text-primary">PHC Artillery</b> <br />Cocaine Village Junction, Off Aba Rd, opposite Genesis, Rumuogba, Port Harcourt.<br /><br />
        </div>
        <div>
        <b className="text-primary">PHC Peter Odili</b> <br />89, Peter Odili Road, Besides Eterna Fueling Station, Port Harcourt<br /><br />
        <b className="text-primary">PHC Ada George</b> <br />No 18 Ada George By Okilton Junction, Port Harcourt.<br /><br />
        <b className="text-primary">PHC Tombia</b> <br />67, Tombia Ext GRA, Port Harcourt.<br /><br />
        <b className="text-primary">PHC Olu Obasanjo</b> <br />61, Olu Obasanjo Road, opposite olu obasanjo roundabout, Port Harcourt.<br /><br /> 
        </div>
        </div>
       `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Sokoto',
    address: `<b className="text-primary">Sokoto</b> <br />3/4 Maiduguri Road Gawon Nama Area<br /><br />
       `,
  },

  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Taraba',
    address: `<b className="text-primary">Jalingo</b> <br />106 White Castle Plaza Barde Way Beside A.U.K Kirbir Shopping Plaza, Jalingo.<br /><br /> `,
  },
  {
    id: uid(8),
    category: 'Nigeria',
    state: 'Yobe',
    address: `<b className="text-primary">Damaturu</b> <br />Shop 2, Adhaza Plaza, Gashuwa Road, Damaturu.<br /><br /> `,
  },

  {
    id: uid(8),
    category: 'Ghana',
    state: 'Ashanti Region',
    address: `<b className="text-primary">Kumasi</b> <br />Solid FM buidling, Central Market Roman Hill Down, Kumasi.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Ghana',
    state: 'Central Region',
    address: `<b className="text-primary">Kasoa</b> <br />Cx-030-9048, Kasoa new market road, opposite Soccabet, Kasoa.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Ghana',
    state: 'Greater Accra',
    address: `<b className="text-primary">Accra</b> <br />3 la Bawalehi road okponglo, beside Ecobank legon Accra.<br /><br />
        <b className="text-primary" >Accra-2</b> <br />126 Spintex Road, By Riklyn Hotel, basket junction, Accra.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Ghana',
    state: 'Nothern Region',
    address: `<b className="text-primary">Tamale</b> <br />M259, Hurlingham street, West Hospital Road Second gate along Tamale submetro.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'Ghana',
    state: 'Western Region',
    address: `<b className="text-primary">Takoradi</b> <br />Plot 65/6 J.H Kobinnah Street, Takoradi.<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'United States',
    state: 'Texas',
    address: `<b className="text-primary">Houston</b> <br />C/O GIGL 14081 Westheimer Road, Houston TX. 77077<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'United Kingdom',
    state: 'England',
    address: `<b className="text-primary">London</b> <br />381-383 Lewisham High St, London SE13 6NZ, United Kingdom<br /><br />
       `,
  },
  {
    id: uid(8),
    category: 'China',
    state: 'China',
    address: `<b className="text-primary">GIG Logistics Warehouse China</b> <br /> 3308, Building 3, Shanxi Building, sanyuanli Avenue, yuexiu District, Guangzhou <br /> <br />
    <b className="text-primary">GIG Logistics Warehouse China</b> <br /> D115 Consolidation Center, 1st Floor, Building D, Guangdabodu, No. 300 Qingcha Road, Shijing Street, Baiyun District, Guangzhou 广州市白云区石井街道庆槎路300号广大播都D栋一楼D115集运中心
      <br /> <br />
    `,
  },
  {
    id: uid(8),
    category: 'Canada',
    state: 'Toronto',
    address: `<b className="text-primary">Toronto</b> <br />60 Rivalda Road, North York, M9M 2M3<br /><br />
       `,
  },
];
